import React from "react";
import "./NodeLearn.css";

function NodeLearn() {
  const nodeDetails = [
    {
      title: "Solid Foundation",
      content: "Master JavaScript fundamentals to excel in Node.js.",
      extra1: "Key Language Skills:",
      detail1: "Understand asynchronous programming and callbacks.",
      extra2: "Core Knowledge:",
      detail2: "Learn Node.js event loop and module systems.",
      icon: "🎓",
    },
    {
      title: "Career Opportunities",
      content: "Explore high-paying roles in backend development.",
      extra1: "Job Titles:",
      detail1: "Backend Developer, Full Stack Developer.",
      extra2: "Average Salary:",
      detail2: "$80k - $130k annually.",
      icon: "💼",
    },
    {
      title: "Scalable Applications",
      content: "Build applications that handle high traffic and large data efficiently.",
      extra1: "Key Features:",
      detail1: "Load balancing, Caching, Asynchronous processing.",
      extra2: "Optimization Techniques:",
      detail2: "Database indexing, API rate limiting.",
      icon: "⚡",
},

      {
        title: "System Architecture",
        content: "Understand the fundamentals of designing scalable and efficient systems.",
        extra1: "Components:",
        detail1: "Load balancers, Microservices, Caching.",
        extra2: "Best Practices:",
        detail2: "High availability, Fault tolerance.",
        icon: "🏗️",
  },
  
    {
      title: "High Demand",
      content: "Node.js skills are highly sought after globally.",
      extra1: "Industry Demand:",
      detail1: "Growing need for scalable backend developers.",
      extra2: "Full Stack Edge:",
      detail2: "Combining frontend and backend expertise.",
      icon: "📈",
    },
    {
      title: "Flexible Ecosystem",
      content: "Create RESTful APIs and real-time applications.",
      extra1: "APIs:",
      detail1: "Learn to build scalable RESTful APIs.",
      extra2: "Real-time Apps:",
      detail2: "Build chat apps and live collaboration tools.",
      icon: "🌐",
    },
  ];

  return (
    <div id='node-learn'>
    <div className="back-node-container">
      <h1 className="node-title">Why Learn Node.js Backend?</h1>
      <p className="node-description">
        Kickstart your backend development journey with Node.js! Explore essential skills, career opportunities, and advanced tools.
      </p>
      <div className="node-cards">
        {nodeDetails.map((detail, index) => (
          <div key={index} className="node-card">
            <div className="node-icon animated-icon">{detail.icon}</div>
            <h3 className="node-card-title">{detail.title}</h3>
            <p className="node-card-content">{detail.content}</p>
            <p>
              <strong>{detail.extra1}</strong> {detail.detail1}
            </p>
            <p>
              <strong>{detail.extra2}</strong> {detail.detail2}
            </p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default NodeLearn;
