import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


const Footer = () => {

  const navigate = useNavigate();

  const handleEnrollClick = () => {
    window.scrollTo(0, 0); 
    navigate("/", { state: { scrollTo: "best-course" } }); 
  };
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="logo-footer">
          <Link to="/">
            <h2>
              Vetri <br />
              <span>Technology Solutions</span>
            </h2>
          </Link>
        </div>

        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
           <li><Link to="/" onClick={() => {
                window.scrollTo(0, 0);      
               }}>Home</Link></li>
           <li><Link to="/about"
              onClick={() => {
                window.scrollTo(0, 0); 
               }}
           >About Us</Link></li>
           <li onClick={handleEnrollClick}>IT Courses</li>
           <li><Link to="/contact"
                  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}           
           >Contact Us</Link></li>
          </ul>
        </div>

          {/* Policy Links */}
          {/* <div className="footer-policy">
          <h3>Policy</h3>
          <ul>
            <li><Link to="/refund-policy">Refund and Returns Policy</Link></li>
            <li><Link to="/terms-service">Terms and Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/shipping-policy">Shipping and Delivery Policy</Link></li>
          </ul>
        </div> */}
      

        <div className="footer-contact">
          <h3>Contact Details</h3>
          <ul className="contact-list">
            <li>
              <FontAwesomeIcon icon={faPhone} className="icon" /> 8438164827 <span> / </span> 8438781327
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} className="icon" /> joinvts@vetriit.com
            </li>
            <li>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              VTS & VIS, April's Complex, Shanthi Complex, Surandai.
            </li>
          </ul>

          <div className="contact-social-icons">
            <a href="https://www.instagram.com/vetritechnologysolutions/?igsh=MW5kODBuaGVrMjlzYw%3D%3D&utm_source=qr#" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="icon" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559691146330&mibextid=ZbWKwL" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="icon" />
            </a>
            <a href="https://www.linkedin.com/company/vetri-technology-solutions/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="icon" />
            </a>
          </div>
        </div>

       
      </div>

      <div className="footer-bottom">
        <p>© 2025 Developed & Maintained by <a href="https://vetriit.com/">VETRI IT SYSTEMS</a>PVT LTD. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
