import React from "react";
import './JavaLearn.css';
import { FaJava, FaMoneyBillWave, FaBriefcase, FaBook, FaLaptopCode, FaCertificate } from 'react-icons/fa';

function JavaLearn() {
    return (
        <div id="java-learn">
            <div className="java-learn-container">
                <h1 className="java-section-title">Why Learn <span>Java</span> Fullstack?</h1>
                <div className="content-wrapper">
                    <div className="content-item">
                        <FaJava className="javafull-icon" />
                        <h3>Master Java Programming</h3>
                        <p>
                            <strong>Master Java:</strong> Start your journey with Java, one of the most powerful and versatile programming languages. Learn object-oriented programming, data structures, and algorithms.
                        </p>
                    </div>

                    <div className="content-item">
                        <FaBriefcase className="javafull-icon" />
                        <h3>Career Opportunities</h3>
                        <p>
                            <strong>Career Boost:</strong> Java developers are in high demand. With skills in Java, you can work in various industries, including finance, healthcare, and e-commerce.
                        </p>
                    </div>

                    <div className="content-item">
                        <FaMoneyBillWave className="javafull-icon" />
                        <h3>Attractive Salary</h3>
                        <p>
                            <strong>High Pay:</strong> Java developers can earn competitive salaries. On average, Java developers in the US make over $100,000 annually, depending on experience and location.
                        </p>
                    </div>

                    <div className="content-item">
                        <FaBook className="javafull-icon" />
                        <h3>Knowledge Hub</h3>
                        <p>
                          <strong>Rich Resources:</strong> Access a wide range of courses, tutorials, books, and videos to deepen your Java knowledge.
                        </p>

                    </div>

                    <div className="content-item">
                        <FaLaptopCode className="javafull-icon" />
                        <h3>Java in Web Development</h3>
                        <p>
                            <strong>Backend Power:</strong> Java is widely used in backend development, especially with frameworks like Spring Boot. Learn how Java powers web applications at scale.
                        </p>
                    </div>

                    <div className="content-item">
                        <FaCertificate className="javafull-icon" />
                        <h3>Professional Growth</h3>
                         <p>
                          <strong>Expertise Matters:</strong> Strengthen your Java skills and stand out in the industry. Demonstrating proficiency in Java development can open doors to better opportunities.
                         </p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default JavaLearn;
