import React from "react";
import "./FrontendLearn.css";
import { FaMoneyBillWave, FaBriefcase, FaChartLine, FaPaintBrush, FaLaptopCode, FaArrowUp } from "react-icons/fa";

function FrontendLearn() {
    return (
        <div id="frontend-learn">
            <div className="frontend-learn-container">
                <h1 className="f-section-title">Why Learn Frontend Development</h1>
                <div className="f-content-wrapper">
                    <div className="f-content-item">
                        <FaMoneyBillWave className="f-icon" />
                        <h3>High Salary Package</h3>
                        <p>
                            As a <strong>frontend developesr</strong>are in demand and often earn lucrative salaries, even as beginners <strong>₹3-6 LPA</strong> (entry-level).
                            
                        </p>
                    </div>
                    <div className="f-content-item">
                        <FaBriefcase className="f-icon" />
                        <h3>Work Opportunities</h3>
                        <p>
                            <strong>Frontend developers</strong> are in demand across industries like e-commerce, IT, and startups.
                        </p>
                    </div>
                    <div className="f-content-item">
                        <FaChartLine className="f-icon" />
                        <h3>Skill Demand</h3>
                        <p>
                            With the <strong>rapid growth of web technologies</strong>, skilled frontend developers are highly sought after globally.
                        </p>
                    </div>
                    <div className="f-content-item">
                        <FaPaintBrush className="f-icon" />
                        <h3>Creative Freedom</h3>
                        <p>
                            <strong>Frontend development</strong> allows you to design visually stunning, user-friendly websites and applications.
                        </p>
                    </div>
                    <div className="f-content-item">
                        <FaLaptopCode className="f-icon" />
                        <h3>Freelance Potential</h3>
                        <p>
                            Work on <strong>freelance projects</strong> globally and earn income while working remotely.
                        </p>
                    </div>
                    <div className="f-content-item">
                        <FaArrowUp className="f-icon" />
                        <h3>Career Growth</h3>
                        <p>
                            Transition into <strong>full-stack development</strong> or specialize in advanced frontend frameworks for a thriving career.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FrontendLearn;
