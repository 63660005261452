import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./BestCourse.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faIndianRupeeSign, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import img1 from '../images/Frontend development.jpg';
import img2 from '../images/UI UX Design.jpg';
import img3 from '../images/python fullstack developer.jpg';
import img4 from '../images/Java Fullstack developer.jpg';
import img5 from '../images/Python backend developer.jpg';
import img6 from '../images/Java backend developer.jpg';
import img7 from '../images/Node js Backend developer.jpg';
import img8 from '../images/Data Analytics Img.jpg';
import img9 from '../images/Data Science.jpg';
import img10 from '../images/Mern fullstack developer.jpg';
import img11 from '../images/Business Analytics.jpg';
import img12 from '../images/Software Testing.jpg';
import img13 from '../images/Digital Marketing.jpg';
import img14 from '../images/Mobile app development.jpg';
import img15 from '../images/aws.jpg';

const courses = [
  { id: 1, title: "Python Fullstack Developer", duration: "90 Days", price: "30,000", image: img3, route:"/python" },
  { id: 2, title: "Java Fullstack Developer", duration: "90 Days", price: "36,000", image: img4, route:"/java" },
  { id: 3, title: "UI/UX Designing", duration: "90 Days", price: "25,000", image: img2, route: "/ui-ux" },
  { id: 4, title: "MERN Stack Developer", duration: "90 Days", price: "30,000", image: img10, route:"/mern-stack" },
  { id: 5, title: "Data Analytics", duration: "90 Days", price: "20,000", image: img8, route:"/data-analytics" },
  { id: 6, title: "Data Science", duration: "90 Days", price: "25,000", image: img9, route:"/data-science" },
  { id: 7, title: "Mobile App Development", duration: "120 Days", price: "45,000", image: img14, route:"/mobile-app" },
  { id: 8, title: "Software Testing", duration: "90 Days", price: "20,000", image: img12, route:"/software-testing" },
  { id: 9, title: "Front-End Developer", duration: "60 Days", price: "15,000", image: img1, route:"/frontend-developer" },
  { id: 10, title: "Python Backend Developer", duration: "60 Days", price: "15,000", image: img5, route:"/backend-python" },
  { id: 11, title: "Java Backend Developer", duration: "60 Days", price: "15,000", image: img6, route:"/backend-java" },
  { id: 12, title: "Node JS Developer", duration: "90 Days", price: "15,000", image: img7, route:"/node" },
  { id: 13, title: "AWS", duration: "90 Days", price: "30,000", image: img15, route:"/aws" },
  { id: 14, title: "Business Analytics", duration: "60 Days", price: "15,000", image: img11, route:"/business-analytics" },
  { id: 15, title: "Digital Marketing", duration: "60 Days", price: "10,000", image: img13, route:"/digital-marketing" },
];

const BestCourse = () => {

  const navigate = useNavigate();
  const sectionRef = useRef(null); 
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo === "best-course") {
      sectionRef.current?.scrollIntoView({ behavior: "smooth" }); 
    }
  }, [location]);

  return (
    <div  ref={sectionRef}>
    <section className="best-course-section">
    <h2 className="section-title">Our <span>Trainings</span></h2>
    <p className="section-subtitle">
      Choosing the IT Training in the IT sector will allow you to grow more professionally and will increase your social network.
    </p>
    <div className="course-grid">
      {courses.map((course, index) => (
        <div className="course-card" key={course.id}>
          <div className="main-part1">
            <div className={`placement-badge ${index < 4 ? "badge-assurance" : "badge-assistance"}`}>
              {index < 4 ? "100% IT Job Assurance" : "100% IT Job Assistance"}
            </div>
            <div className="live-classes-badge">
              <FaVideo className="course-icon1" />
              Live Classes
            </div>
            <img src={course.image} alt={`Course on ${course.title}`} className="course-image" />
          </div>
          <div className="main-part">
            <h3 className="course-title">{course.title}</h3>
             <div className="course-details">
              <span className="course-item">
                <FontAwesomeIcon icon={faClock} className="course-icon" />
                <span className="course-text1">{course.duration}</span>
              </span>
              <button
                className="join-button"
                onClick={() => {
                window.scrollTo(0, 0); 
               navigate(course.route || "/"); 
               }}
              >
              Learn More
              </button>

            </div>
           
            <p className="course-text"> <FontAwesomeIcon icon={faIndianRupeeSign} className="course-icon" />{course.price} <span className="course-gst">(Incl of GST)</span></p>

            <div className="course-emi">
            <FontAwesomeIcon icon={faCreditCard} className="emi-icon" />
            {course.title === "Data Analytics" ? "2 Months No Cost EMI" : (course.duration === "90 Days" || course.duration === "120 Days" ? "3 Months No Cost EMI" : "2 Months No Cost EMI")}
            </div>
          
          </div>
        </div>
      ))}
    </div>
  </section>
  </div>
  );
};

export default BestCourse;
