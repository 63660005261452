import React from "react";
import  "../uiux/UiUxRoadMap.css";
import img1 from '../images/Assistance roadmap.jpg';

function DigitalRoadMap(){
    return(
        <div className="uiux-roadmap-container">
           <h1>VTS <span>Digital Marketing</span> Placement Assistance Roadmap</h1>
           <div className="uiux-roadmap-img">
               <img src={img1}  alt="wait" />
           </div>
        </div>
    )
}
export default DigitalRoadMap;