import React from "react";
import "./WhatsApp.css"; // Ensure this file is correctly linked
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsApp = () => {
  const phoneNumber = "918438164827"; // WhatsApp number (no '+' in URL)
  const prefilledMessage = encodeURIComponent(
    "Hello! Team, Can I know more about VTS Training & Placement Program?"
  );

  return (
    <div className="whatsapp-container">
      <a
        href={`https://wa.me/${phoneNumber}?text=${prefilledMessage}`} // Using wa.me for better compatibility
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </div>
  );
};

export default WhatsApp;
