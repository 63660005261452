import React, { useState } from "react";
import "../uiux/UiUxDesign.css";
import { FaCalendarAlt, FaChalkboardTeacher, FaUserGraduate, FaCertificate, FaLaptop, FaProjectDiagram, FaHandshake, FaBriefcase, FaCheckCircle, FaBook, FaVideo, FaHome } from "react-icons/fa";
import mob from "../images/Mobile app development.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign,
  faEnvelope,
  faUser,
  faMobileAlt, } from "@fortawesome/free-solid-svg-icons";

const MobApp = () => {
 const [formVisible, setFormVisible] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
    enquiry: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleDownloadClick = () => {
    setErrors({});
    setFormVisible(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
  
    if (!formData.name || !/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = "Name must contain only letters and spaces.";
    }
  
    if (
      !formData.email ||
      !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(formData.email)
    ) {
      newErrors.email = "Enter a valid email address in lowercase.";
    }
  
    if (!formData.whatsapp || !/^\d{10}$/.test(formData.whatsapp)) {
      newErrors.whatsapp = "WhatsApp number must be exactly 10 digits.";
    }
  
    if (!formData.enquiry) {
      newErrors.enquiry = "Please select an enquiry type.";
    }
  
    return newErrors;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await fetch("https://vts-backend.onrender.com/submit-form", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert("Please Click Ok to Download the VTS Brochure!!!");
          
          setTimeout(() => {
            // Download the brochure
            const link = document.createElement("a");
            link.href = "/brochure/MOBILEAPPDEV.pdf";
            link.download = "VTS_Mobile_App_Syllabus.pdf";
            link.click();

            setFormData({
              name: "",
              email: "",
              whatsapp: "",
              enquiry: "",
              message: "",
            });

            setFormVisible(false);
            setIsLoading(false); 
          }, 1000); 
        } else {
          console.error("Failed to submit form");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    } else {
      setErrors(formErrors);
      setIsLoading(false);
    }
  };
  return (
    <div className="pro-main">
        <h1>Mobile App <span>Development</span></h1>
      <div className="uiux-container">
      <div className="left-section">
        <p>
        Mobile app development involves creating applications for 

smartphones and tablets using platforms like Android 

Studio for Android and Xcode for iOS. Android 

development typically uses Java or Kotlin, while iOS

development relies on Swift or Objective-C. Both 

platforms offer powerful integrated development 

environments (IDEs) that allow developers to design, 

code, test, and deploy apps. Android Studio provides tools

for building and debugging apps for various Android 

devices, while Xcode offers similar tools for iOS, including

simulators and performance monitoring.
        </p>
        <h2 className="fees-animation">
          Training Fees -<FontAwesomeIcon icon={faIndianRupeeSign} className="fees-icon" />
          45,000 Incl of GST (3 Months No Cost EMI) 
          </h2>
        <button className="download-btn" onClick={handleDownloadClick}>
          Download Brochure
        </button>
      </div>
      <div className="right-section">
        <div className="work-card">
          <img src={mob} alt="Mobile" className="card-image" />
          <ul className="card-content">
            <li>
              <span className="icon-with-text">
                <FaCalendarAlt className="icon" />
                <span>Duration</span>
              </span>
              <span className="value bright">120 Days</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaChalkboardTeacher className="icon" />
                <span>Training Mode</span>
              </span>
              <span className="value bright">Online/Offline</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaUserGraduate className="icon" />
                <span>Skill Level</span>
              </span>
              <span className="value bright">Expert</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaCertificate className="icon" />
                <span>Certification</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaLaptop className="icon" />
                <span>Live Classes</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaProjectDiagram className="icon" />
                <span>Live Project Training</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaHandshake className="icon" />
                <span>Internship Offer</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaBriefcase className="icon" />
                <span>Job Offer</span>
              </span>
              <span className="value bright">No</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaHome className="icon" />
                <span>Work From Home</span>
              </span>
              <span className="value bright">No</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaCheckCircle className="icon" />
                <span>Placement Assistance</span>
              </span>
              <span className="value bright">100%</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaBook className="icon" />
                <span>Training Material</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
            <li>
              <span className="icon-with-text">
                <FaVideo className="icon" />
                <span>Training Recordings</span>
              </span>
              <span className="value bright">Yes</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

      {formVisible && (
            <div className="form-popup">
              <div className="form-container">
                <button
                  className="close-btn"
                  onClick={() => setFormVisible(false)}
                >
                  &times;
                </button>
                <h3>Fill the Form to Download the Brochure</h3>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>
                      <FontAwesomeIcon icon={faUser} className="input-icon" />
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter Your Name"
                    />
                    {errors.name && <p className="error-text">{errors.name}</p>}
                  </div>
                  <div className="form-group">
                    <label>
                      <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter Your Email"
                    />
                    {errors.email && <p className="error-text">{errors.email}</p>}
                  </div>
                  <div className="form-group">
                    <label>
                      <FontAwesomeIcon
                        icon={faMobileAlt}
                        className="input-icon"
                      />
                      WhatsApp Number
                    </label>
                    <input
                      type="tel"
                      name="whatsapp"
                      value={formData.whatsapp}
                      onChange={handleChange}
                      placeholder="Enter Your WhatsApp Number"
                    />
                    {errors.whatsapp && (
                      <p className="error-text">{errors.whatsapp}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Enquiry Type</label>
                    <select
                      name="enquiry"
                      value={formData.enquiry}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select Your Request Type
                      </option>
                      <option value="frontend">🎨 Frontend Developer</option>
                      <option value="backend">🔧 Backend Developer (Python)</option>
                      <option value="node">🛠️ Backend Developer (Node.js)</option>
                      <option value="java">☕ Backend Developer (Java)</option>
                      <option value="mern">💻 MERN Stack Developer</option>
                      <option value="pyfull">🐍 Python Fullstack Developer</option>
                      <option value="javfull">☕ Java Fullstack Developer</option>
                      <option value="mob">📱 Mobile App Development</option>
                      <option value="analytics">📊 Data Analytics</option>
                      <option value="science">🔬 Data Science</option>
                      <option value="uiux">✨ UI/UX Design</option>
                      <option value="digital">📈 Digital Marketing</option>
                      <option value="software">🔍 Software Testing</option>
                      <option value="aws">☁️ AWS</option>
                      <option value="business">📉 Business Analytics</option>
                    </select>
                    {errors.enquiry && (
                      <p className="error-text">{errors.enquiry}</p>
                    )}
                  </div>
                 {/* Show loading animation when submitting */}
               {isLoading && <div className="loader"></div>}

<button type="submit" className="submit-button" disabled={isLoading}>
  {isLoading ? (
    <>
      <div className="spinner"></div> Processing...
    </>
  ) : (
    "Submit"
  )}
</button>
                </form>
              </div>
            </div>
          )}

<style>
        {`
          .loader {
            text-align: center;
            font-size: 14px;
            color: #3498db;
            margin-bottom: 10px;
          }

          .spinner {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 3px solid rgba(255, 255, 255, 0.3);
            border-top: 3px solid white;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            margin-right: 8px;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          .submit-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ff8016;
            color: white;
            padding: 10px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            transition: background 0.3s;
          }

          .submit-button:disabled {
            background-color: #5a6268;
            cursor: not-allowed;
          }

          .submit-button:disabled .spinner {
            border-top: 3px solid #ccc;
          }
        `}
      </style>
    </div>
  );
};

export default MobApp;
