import React from 'react';
import './ScienceLearn.css';

function ScienceLearn() {
  return (
    <div id="data-learn">
      <div className="data-learn-container">
        <h1 className="data-learn-title">Why Learn Data Science?</h1>
        <div className="data-learn-grid">
        <div className="data-learn-card">
  <div className="data-icon">📊</div>
  <h2 className='d-h2'>Data Analysis</h2>
  <p className='d-p'>Enhance your skills in processing and interpreting complex datasets effectively.</p>
  <p className='d-p1'><strong>Real-World Application:</strong> Gain expertise in handling real-world data challenges with practical applications.</p>
  <p className='d-p1'><strong>Problem-Solving:</strong> Develop problem-solving abilities using structured and unstructured data sources.</p>
</div>


          <div className="data-learn-card">
            <div className="data-icon">💻</div>
            <h2 className='d-h2'>Data Science Careers</h2>
            <p className='d-p'>Explore career opportunities in data science and machine learning.</p>
            <p className='d-p1'><strong>Average Salary:</strong> ₹6,00,000 - ₹20,00,000 per year in India.</p>
            <p className='d-p1'><strong>Job Roles:</strong> Data Scientist, Machine Learning Engineer, Data Analyst.</p>
          </div>

          <div className="data-learn-card">
  <div className="data-icon">⚙️</div>
  <h2 className='d-h2'>Machine Learning</h2>
  <p className='d-p'>Explore the fundamentals of machine learning and its real-world applications.</p>
  <p className='d-p1'><strong>Practical Insights:</strong> Learn to process data and extract meaningful patterns.</p>
  <p className='d-p1'><strong>Industry Use Cases:</strong> Discover how machine learning is applied in different domains.</p>
</div>


          <div className="data-learn-card">
            <div className="data-icon">🌐</div>
            <h2 className='d-h2'>Big Data</h2>
            <p className='d-p'>Learn to work with large-scale datasets and big data technologies.</p>
            <p className='d-p1'><strong>Scalability:</strong> Learn how to manage and process vast amounts of data efficiently.</p>            
            <p className='d-p1'><strong>Processing Power:</strong> How to scale data processing efficiently.</p>
          </div>

          <div className="data-learn-card">
  <div className="data-icon">📈</div>
  <h2 className='d-h2'>Data Visualization</h2>
  <p className='d-p'>Create compelling visualizations to communicate insights effectively.</p>
  <p className='d-p1'><strong>Design Principles:</strong> Understand how to create clear and impactful data representations.</p>
  <p className='d-p1'><strong>Metrics:</strong> Learn key metrics for business intelligence and reporting.</p>
</div>


<div className="data-learn-card">
  <div className="data-icon">📚</div>
  <h2 className='d-h2'>Statistical Methods</h2>
  <p className='d-p'>Master statistical techniques for hypothesis testing and data sampling.</p>
  <p className='d-p1'><strong>Statistical Concepts:</strong> Mean, Median, Mode, Variance, and Correlation.</p>
  <p className='d-p1'><strong>Data Distribution:</strong> Understand normal distribution, skewness, and kurtosis.</p>
</div>

        </div>
      </div>
    </div>
  );
}

export default ScienceLearn;
