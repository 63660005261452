import React from "react";
import './SoftwareLearn.css'; 
import { FaUser, FaSuitcase, FaDollarSign, FaBookOpen, FaStar, FaToolbox } from 'react-icons/fa';


function SoftwareLearn() {
    return (
        <div id="software-learn">
            <div className="software-learn-container">
                <h1 className="s-section-title">Why Learn Software Testing?</h1>
                <div className="s-content-wrapper">
                    <div className="s-content-item">
                        <FaUser className="s-icon" />
                        <h3>Master Software Testing</h3>
                        <p>Start your journey with software testing, learning how to write test cases, identify defects, and ensure the quality of software products.</p>
                    </div>

                    <div className="s-content-item">
                        <FaSuitcase className="s-icon" />
                        <h3>Career Opportunities</h3>
                        <p>Software testers are in high demand across various industries, including IT, healthcare, finance, and e-commerce. Learn the skills needed to secure a rewarding job in this field.</p>
                    </div>

                    <div className="s-content-item">
                        <FaDollarSign className="s-icon" />
                        <h3>Attractive Salary</h3>
                        <p>Software testers can earn competitive salaries. On average, QA engineers and testers in the US make over $80,000 annually, depending on experience and location.</p>
                    </div>

                    <div className="s-content-item">
    <FaBookOpen className="s-icon" />
    <h3>Practical Experience</h3>
    <p>Gain hands-on experience by working on real-world projects, case studies, and simulations. Practical application enhances understanding and prepares you for real challenges in software testing.</p>
</div>


<div className="s-content-item">
    <FaToolbox className="s-icon" />
    <h3>Manual Testing</h3>
    <p>Understand the fundamentals of manual testing, including test case design, execution, and defect tracking. Manual testing plays a crucial role in ensuring software quality before automation.</p>
</div>


<div className="s-content-item">
    <FaStar className="s-icon" />
    <h3>Career Growth</h3>
    <p>Develop essential skills and gain industry experience to advance in the field of software testing. Stay updated with the latest trends and best practices to enhance your expertise.</p>
</div>

                </div>
            </div>
        </div>
    );
}
export default SoftwareLearn;