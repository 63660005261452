import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../images/logo2.png';
import vetri from '../images/abt3-removebg-preview.png';
import {
  FaPython,
  FaJava,
  FaPaintBrush,
  FaBug,
  FaNodeJs,
  FaCode,
  FaServer,
  FaReact,
  FaBullhorn,
  FaCloud,
  FaChartLine,
  FaDatabase,
  FaMobileAlt,
  FaChartBar,
  FaChevronDown
} from 'react-icons/fa';

const Navbar = () => {
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);

  const toggleMegaMenu = () => {
    setMegaMenuOpen(!isMegaMenuOpen);
  };

  return (
    <nav className="navbar">
      <div class="logo-container">
      <Link to="/"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
        <div className="logo">
          <img src={logo} alt="Main Logo" className="logo-img" />
          <img src={vetri} alt="Extra Logo" className="extra-logo" />
        </div>
      </Link>

      <div className="confetti" style={{ left: '10%', animationDelay: '0s', background: '#FFD700' }}></div>
      <div className="confetti" style={{ left: '30%', animationDelay: '0.5s', background: '#FF4500' }}></div>
      <div className="confetti" style={{ left: '50%', animationDelay: '1s', background: '#32CD32' }}></div>
      <div className="confetti" style={{ left: '70%', animationDelay: '1.5s', background: '#1E90FF' }}></div>
      <div className="confetti" style={{ left: '90%', animationDelay: '2s', background: '#FF69B4' }}></div>
      </div>

      <ul className="nav-links">
        <li className="nav-link mega-menu" onClick={toggleMegaMenu}>
        <span>IT Courses</span>
          <FaChevronDown className={`chevron-icon ${isMegaMenuOpen ? "rotate" : ""}`}/> 
           {isMegaMenuOpen && (
            <div className="mega-menu-content">
              <Link to="/python" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaPython className="icon-nav" />
                Python Full Stack
              </Link>
              <Link to="/java" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaJava className="icon-nav" />
                Java Full Stack
              </Link>
              <Link to="/ui-ux" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaPaintBrush className="icon-nav" />
                UI/UX Designing
              </Link>
              <Link to="/data-analytics" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaChartBar className="icon-nav" />
                Data Analytics
              </Link>
              <Link to="/data-science" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaDatabase className="icon-nav" />
                Data Science
              </Link>
              <Link to="/mern-stack" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaReact className="icon-nav" />
                MERN Stack Developer
              </Link>
              <Link to="/mobile-app" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaMobileAlt className="icon-nav" />
                Mobile App Development
              </Link>
              <Link to="/software-testing" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaBug className="icon-nav" />
                Software Testing
              </Link>
              <Link to="/frontend-developer" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaCode className="icon-nav" />
                Frontend Developer
              </Link>
              <Link to="/backend-python" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaPython className="icon-nav" />
                Backend Developer (Python)
              </Link>
              <Link to="/backend-java" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaServer className="icon-nav" />
                Backend Developer (Java)
              </Link>
              <Link to="/node" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaNodeJs className="icon-nav" />
                Backend Developer (Node.js)
              </Link>
              <Link to="/aws" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaCloud className="icon-nav" />
                AWS
              </Link>
              <Link to="/business-analytics" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaChartLine className="icon-nav" />
                Business Analytics
              </Link>
              <Link to="/digital-marketing" className="mega-item"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >
                <FaBullhorn className="icon-nav" />
                Digital Marketing
              </Link>
            </div>
          )}
        </li>
        <li><Link to="/about"  onClick={() => {
                    window.scrollTo(0, 0); 
                   }}   >About Us</Link></li>
      </ul>
      <button className="enquiry-button">
      <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}
           onClick={() => {
            window.scrollTo(0, 0); 
           }}
      >Contact Us</Link>
      </button>
    </nav>
  );
};

export default Navbar;
