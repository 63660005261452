import React, { useState } from "react";
import "../software/SoftwareFaq.css";

const BackPyFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "The Training will be Live Classes or Recorded Sessions?",
      answer:
        "All the IT Trainings are Live Classes only via Microsoft Teams/Offline. The recordings for all the classes can be accessed for the lifetime by the Candidate.",
    },
    {
      question: "Any Placement Guidance/Placement Assistance provided?",
      answer:
        "Yes, all VTS Trainees are eligible for the Placement Training Program, which is free of cost for all the Trainees. This includes Resume Building, Portfolio Creation, Business English, Mock Interviews, etc. This Program will help the Candidates to get the Job offer in the IT Sector.",
    },
    {
      question: "Internship & Placement Assistance would be provided at the same time ? ",
      answer:
        "Yes, all candidates in the Placement Assistance Program are eligible for a 3-month internship (after completing the training and obtaining the completion certificate) with a stipend to gain real-world practical experience.",
    },
    {
      question: "What would be the Training Hours Per Day?",
      answer:
        "Training will be  3 to 5 Hours per day: 1.5 hours of Classroom/Virtual Classroom Training followed by 1.5 to 3.5 hours of Project Training. The Trainings will be taken only from  Monday to Saturday (9:00 AM to 8:00 PM). No Weekend Classes available now. ",
    },
    {
      question: "Any One-One Session is available with Mentors for Doubts Clearance ? ",
      answer: "Yes, after the Daily Training session gets Over, One-One Session can be availed by the Trainee with Mentor to clear the doubts.",
    },
    {
      question: "How Many Candidates Per Batch will be Allocated?",
      answer: "We restrict batches to 5 to 10 trainees to ensure effective learning.",
    },
    {
      question:
        "How to Join the Placement Assistance Program?",
      answer:
      "Kindly reach out to our Customer Support team at +91 8438164827 / +91 8438781327. The Customer Support team will assist you on the documents required to Join the Program.",
    },
    {
      question: "Any Exams will be Conducted in the Training Period?",
      answer:
        "Yes, weekly exams and reviews will be conducted. Successful completion of Training and Internship is required for placement.",
    },
    {
      question:
        "IT Training Certificate will be given Post Completion of IT Training?",
      answer:
        "Yes, a Physical & Soft Copy of Certificate with Online Login & QR Code Verification will be provided. The certificate is ISO 9001:2015 certified and can be downloaded anytime with a login Provided to the Candidate by VTS.",
    },
    {
      question: "Who will get the Internship with Stipend?",
      answer:
        "All VTS Trainees are eligible to get the Internship with Stipend after successfully completing their 3 Months of IT Training.",
    },
    {
      question: "What is the Internship Period?",
      answer:
        "The internship period is 3 months post-training completion and involves 7 continuous working hours per day with VETRI IT SYSTEMS PRIVATE LIMITED (Online/Offline)."  
     },
    {
      question: "Any Projects Would be Provided in the Internship Period?",
      answer:
        "Yes, projects will be allocated by the Project Manager, and candidates must complete them within the allocated timeline.",
    },
    {
      question: "Internship Certificate will be provided Post Completion?",
      answer:
        "Yes, an Internship Certificate will be provided upon successful completion. It will include details of all projects completed during the internship.",
    },
    {
      question: "How many months of placement support would be provided?",
      answer:
        "Placement Training Program is free of cost and will be given for 20 to 25 days (1 Hour per day). Placement support will be provided with lifetime validity along with access to the WhatsApp Community Channel.",
    },
 
    {
      question: "What is the the office working timings?",
      answer:
        "You can call us between 10.00 AM to 07.00 PM (Monday to Saturday - excluding public holidays). Our office number is - 8438164827.",
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="assistance-faq-container">
      <div className="assistance-faq-main">
      <h1 className="assistance-faq-title">
        Frequently <span>Asked</span> Questions
      </h1>
      </div>
      <div className="assistance-faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="assistance-faq-item">
            <div
              className="assistance-faq-question"
              onClick={() => toggleAnswer(index)}
            >
              {faq.question}
              <span className="assistance-toggle-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && (
              <div className="assistance-faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackPyFaq;
